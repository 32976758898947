import { fetcher } from '@lib/fetcher';

import { LOG_IN } from './LogIn.mutation';
import { transformLogIn } from './LogIn.transform';
import { Variables } from './types';

export default function useLogin() {
  function mutate(variables: Variables) {
    if (
      process.env.NEXT_PUBLIC_READ_ONLY &&
      process.env.NEXT_PUBLIC_READ_ONLY === 'true'
    ) {
      window.location.href =
        process.env.NEXT_PUBLIC_READ_ONLY_REDIRECT || '/404';
      throw new Error('Maintenance mode is on');
    }

    return fetcher(LOG_IN, variables)
      .then((data) => {
        return transformLogIn(data);
      })
      .catch((error) => {
        if (variables && variables.data.googleId) {
          if (
            error &&
            JSON.stringify(error).includes('"message":"Wrong Credentials"')
          ) {
            // console.log('GOOGLE ID ERROR');
            return {
              error:
                'This Google account is not registered with us. Please sign up first.',
            };
          }
        }
        // console.log('useLogin error variables', variables);
        // console.log('useLogin mutate error', error);
        throw error;
      });
  }

  return { mutate };
}
