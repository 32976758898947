
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/index.scss';
import '/public/fonts/fonts.css';
import React, { useEffect, useState, useMemo } from 'react';
import App from 'next/app';
import { AppContext } from 'next/app';
// import withGA from 'next-ga';
import { AuthProvider } from '../providers/AuthProvider/AuthProvider';
import CookieBanner from '@components/common/CookieBanner/CookieBanner';
// import { ProfileStatusNotification } from '@common/ProfileStatusNotification';
// import MaintenancePage from '@components/Maintenance';
import Footer from '@components/organisms/Footer/Footer';
import { GET_COOKIE_BANNER } from '@data/strapi/CookieBanner/GetCookieBannerQuery.query';
import { GET_FOOTER } from '@data/strapi/Footer/Query/GetFooter/GetFooterQuery.query';
import { GET_HEADER } from '@data/strapi/Header/Query/GetHeader/GetHeaderQuery.query';
import { GET_HEADER_CORPORATE } from '@data/strapi/HeaderCorporate/Query/GetHeaderCorporate/GetHeaderCorporateQuery.query';
import Head from 'next/head';
import Header from '@components/organisms/Header/Header';
import HeaderCorporate from '@components/organisms/HeaderCorporate/Header';
import IdleTimerLocalProvider from '../providers/IdleTimerLocalProvider/IdleTimerLocalProvider';
import { IdleTimerNotification } from '@components/molecules/IdleTimerNotification';
import MaintenanceNotification from '@components/common/MaintenanceNotification/MaintenanceNotification';
import MaintenancePage from '@components/common/Maintenance';
// import { Button } from '@common/Button';
import NProgress from 'nprogress';
import PreviewBox from '@components/common/PreviewBox/PreviewBox';
import { ProfileStatusNotification } from '@components/molecules/ProfileStatusNotification';
import Router from 'next/router';
import Script from 'next/script';
// import TagManager from 'react-gtm-module';
import { createSeo } from '@constants/utils';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import { hasWindow } from '@utils/hasWindow';
// import { hotjar } from 'react-hotjar';
import { isBoolean } from 'lodash';
import { isClientSide } from '@utils/isClientSide';
import localFont from "next/font/local";
import { useRouter } from 'next/router';
// import { GET_FOOTER } from '@data/strapi/Footer/Query/GetFooter/GetFooterQuery.query';
// import { GET_NAVBAR } from '@data/strapi/Navbar/Query/GetNavbar/GetNavbarQuery.query';
import withGA from '@hooks/withGA/withGA';
import { isProductionEnv } from '@utils/isProductionEnv';
import useTranslation from 'next-translate/useTranslation';
import SurveyBar from '@components/molecules/SurveyBar';
import Link from 'next/link';
// const sourceSans = Source_Sans_3({
//   variable: '--font-sourceSans',
//   weight: ['400', '600', '700'],
//   style: ['normal', 'italic'],
//   subsets: ['latin'],
//   display: 'block',
// });
export const sourceSans = localFont({
    variable: '--font-sourceSans',
    src: [
        {
            path: "../../public/fonts/source_sans_3/SourceSans3-Regular.woff2",
            weight: "400",
            style: "normal",
        },
        {
            path: "../../public/fonts/source_sans_3/SourceSans3-SemiBold.woff2",
            weight: "600",
            style: "normal",
        },
        {
            path: "../../public/fonts/source_sans_3/SourceSans3-Bold.woff2",
            weight: "700",
            style: "normal",
        },
    ],
    // declarations: [{ prop: 'ascent-override', value: '110%' }]
});
declare global {
    interface Window {
        BE_API?: any;
    }
}
/**
 * Accessibility tool: Outputs to devtools console on dev only and client-side only.
 * @see https://github.com/dequelabs/react-axe/issues/125
 */
if (process.env.NODE_ENV !== 'production' && isClientSide() && hasWindow()) {
    const React = require('react'); // eslint-disable-line
    const DOM = require('react-dom'); // eslint-disable-line
    const axe = require('@axe-core/react'); // eslint-disable-line
    const context = {
        include: [['#__next']],
    };
    // axe(React, DOM, 1000, {}, context);
}
// TODO: If you want to send metrics to a analytics service
// export function reportWebVitals(metric) {
//     metric.label === 'web-vital' && console.log(metric);
// }
// const tagManagerArgs = process.env.NEXT_PUBLIC_GTM_ID
//   ? {
//     gtmId: process.env.NEXT_PUBLIC_GTM_ID,
//   }
//   : undefined;
function DevOverlay() {
    if (!process.env.NEXT_PUBLIC_IS_DEV) {
        return null;
    }
    return <div className={'dev-overlay h5'}>STAGING ENVIRONMENT</div>;
}
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
//Router.events.on('routeChangeError', () => NProgress.error());
function MyApp({ Component, pageProps, initialData, initialHeaderData, initialHeaderCorporateData, initialFooterData, initialCookieData, variables, }: any) {
    // console.log('MAINTENANCE MODE: ', process.env.NEXT_PUBLIC_MAINTENANCE_MODE);
    // console.log('GTM ID: ', process.env.NEXT_PUBLIC_GTM_ID);
    const draftMode = pageProps.draftMode;
    const router = useRouter();
    const isCorporateRoute = router.pathname.startsWith('/corporate-partners');
    const [shouldLoadAnalytics, setShouldLoadAnalytics] = useState(false);
    const [shouldLoadDapAnalytics, setShouldLoadDapAnalytics] = useState(false);
    const { t } = useTranslation('common');
    useEffect(() => {
        document.documentElement.classList.add(sourceSans.className);
        document.documentElement.classList.add(sourceSans.variable);
        if (router.isReady) {
            const query = router.query;
            // Load if in production, or
            // in non-prod with ?debugAnalytics=<any_value>
            if (isProductionEnv() || (query && query.debugAnalytics)) {
                setShouldLoadAnalytics(true);
            }
            // Load DAP script if in production, or
            // in non-prod with ?debugDap=<any_value>
            if (isProductionEnv() || (query && query.debugDap)) {
                setShouldLoadDapAnalytics(true);
            }
        }
        // if (
        //   !process.env.NEXT_PUBLIC_HOTJAR_ID ||
        //   !process.env.NEXT_PUBLIC_HOTJAR_VERSION
        // )
        //   return;
        // hotjar.initialize(
        //   +process.env.NEXT_PUBLIC_HOTJAR_ID,
        //   +process.env.NEXT_PUBLIC_HOTJAR_VERSION
        // );
    }, [router.isReady, router.query]);
    const analyticsScripts = useMemo(() => {
        return shouldLoadAnalytics ? (<>
        <Script strategy="lazyOnload" id="gtag-script" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}/>

        <Script strategy="lazyOnload">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}', {
            page_path: window.location.pathname,
            });
          `}
        </Script>
      </>) : null;
    }, [shouldLoadAnalytics]);
    const dapAnalyticsScript = useMemo(() => {
        return shouldLoadDapAnalytics ? (<>
        <Script id="_fed_an_ua_tag" strategy="lazyOnload" src={`https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=${process.env.NEXT_PUBLIC_DAP_AGENCY || ''}`} async/>
      </>) : null;
    }, [shouldLoadDapAnalytics]);
    // useEffect(() => {
    //   if (tagManagerArgs && typeof window !== 'undefined') {
    //     TagManager.initialize(tagManagerArgs);
    //   }
    // }, []);
    return process.env.NEXT_PUBLIC_MAINTENANCE_MODE &&
        process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ? (<>
      <Head>{createSeo()}</Head>
      <MaintenancePage />
    </>) : (<AuthProvider>
      <IdleTimerLocalProvider>
        <DevOverlay />
        {draftMode && <PreviewBox></PreviewBox>}
        <section aria-label={t('notifications.label')}>
          <MaintenanceNotification />
          <ProfileStatusNotification />
          <IdleTimerNotification />
        </section>
        {isCorporateRoute ?
            <>
            <HeaderCorporate initialData={initialHeaderCorporateData} variables={variables}/>
           <SurveyBar label={<>
                 <span className="textValue">{`Help us improve "For Corporate Partners" on `}</span>
                 <Link href="https://www.workwithusaid.gov/">WorkwithUSAID.gov</Link>
                 <span className="textValue">{'. '}</span>
                 {/* <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdDKY7gIZNgwzup3zIHL96FX1W_KNtroC_P7J0APYVx92oJcQ/viewform">
                    Take this one-minute survey
                    </Link>
                    <span className="textValue" >{'.'}</span> */}
               </>} button={{ url: "https://docs.google.com/forms/d/e/1FAIpQLSdDKY7gIZNgwzup3zIHL96FX1W_KNtroC_P7J0APYVx92oJcQ/viewform", label: "Take this one-minute survey" }} className={`survey-bar`}></SurveyBar>
             </> :
            <Header initialData={initialHeaderData} variables={variables}/>}
        <div className={`main-page`} id="page-content">
          <main className="main-content">
            <Component {...pageProps}/>
          </main>
        </div>

        <CookieBanner initialData={initialCookieData}/>

        <Footer initialData={initialFooterData}/>

        {(process.env.NEXT_PUBLIC_IS_DEV || process.env.NODE_ENV === 'production') && (<>
            <Script strategy="lazyOnload" id="accessibility-script" async>
              {`(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : true, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#002f6c', triggerColor : '#002f6c', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'small', triggerOffsetX : 60, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 60, triggerOffsetY : 10, triggerRadius : '50%' } }); }; h.appendChild(s); })();`}
            </Script>
          </>)}

        {/* Analytics scripts. */}
        {analyticsScripts}

        {/* DAP Analytics script. */}
        {dapAnalyticsScript}
      </IdleTimerLocalProvider>
    </AuthProvider>);
}
// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (appContext: AppContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const locale = appContext.router.locale;
    const variables = {
        id: 'navbar',
        idType: 'NAME',
        locale,
    };
    const variablesStrapi = { locale };
    const draftMode = appContext?.router?.isPreview;
    // Fetch
    const headerData = await fetcherStrapi(GET_HEADER, variablesStrapi, draftMode);
    const headerCorporateData = await fetcherStrapi(GET_HEADER_CORPORATE, variablesStrapi, draftMode);
    const footerData = await fetcherStrapi(GET_FOOTER, variablesStrapi, draftMode);
    const cookieData = await fetcherStrapi(GET_COOKIE_BANNER, variablesStrapi, draftMode);
    if (isBoolean(process.env.NEXT_PUBLIC_MAINTENANCE_MODE)) {
        return {
            initialData: {},
            variables,
            revalidate: 120,
        };
    }
    return {
        ...appProps,
        initialData: {},
        variables,
        initialHeaderCorporateData: headerCorporateData,
        initialHeaderData: headerData,
        initialFooterData: footerData,
        initialCookieData: cookieData,
        revalidate: 120,
    };
};
const __Next_Translate__Page__19498cf9093__ = withGA(process.env.NEXT_PUBLIC_GA, Router)(MyApp);

    export default __appWithI18n(__Next_Translate__Page__19498cf9093__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  